import { pinkVariables, variables } from '@setplex/arcana'
import '@setplex/arcana/styles.css'
import { useLayoutEffect } from 'react'

interface Props {
  children: React.ReactNode
}

const AVAILABLE_THEMES: Record<AppTheme, string> = {
  pink: pinkVariables,
}
const getEraTheme = () => {
  const theme = window.ERA_CONFIG?.theme

  return theme ? AVAILABLE_THEMES[theme] : ''
}
const ERA_THEME = getEraTheme()

/**
 * Not actually a _context_ provider, just wrapper for styling
 */

ThemeProvider.displayName = 'ThemeProvider'

export function ThemeProvider({ children }: Props): JSX.Element {
  useLayoutEffect(() => {
    document.body.classList.add(variables)
    ERA_THEME && document.body.classList.add(ERA_THEME)
  }, [])

  return <div className={`${variables} ${ERA_THEME} layout`}>{children}</div>
}
